<template>
  <Head>
    <title v-if="props.branch.customDomain?.customDomain">
      {{ props.branch.extendedBranchName }}
    </title>
    <title v-else>
      Street Insights
    </title>
    <link
      v-if="props.branch.customDomain?.customDomain"
      rel="icon"
      type="image/png"
      sizes="16x16"
      :href="`https://www.google.com/s2/favicons?domain=${props.branch.website}`"
    >
    <meta
      name="twitter:card"
      content="summary_large_image"
    >
    <meta
      property="og:type"
      content="website"
    >
    <meta
      property="og:title"
      :content="props.branch.customDomain?.customDomain ? props.branch.extendedBranchName : 'Street Insights'"
    >
    <meta
      v-if="content.description"
      name="description"
      :content="content.description"
    >
    <meta
      v-if="content.description"
      property="og:description"
      :content="content.description"
    >
    <meta
      v-if="props.content.defaultImageSelected ? `/${props.content.selectedImageUrl}` : props.content.selectedImageUrl"
      property="og:image"
      :content="props.content.defaultImageSelected ? `${page.props.app.url}/${props.content.selectedImageUrl}` : props.content.selectedImageUrl"
    >
    <meta
      property="og:url"
      :content="url"
    >
    <meta
      property="og:locale"
      :content="page.props.app.locale"
    >

    <link
      rel="stylesheet"
      :href="'https://fonts.googleapis.com/css?family=' + fonts.title"
    >
    <link
      rel="stylesheet"
      :href="'https://fonts.googleapis.com/css?family=' + fonts.body"
    >
    <link
      rel="stylesheet"
      :href="'https://fonts.googleapis.com/css?family=' + fonts.buttons"
    >

    <Component :is="'style'">
      :root {
      --lead-capture-title-font: {{ branding.titlesFont }};
      --lead-capture-title-colour: {{ branding.titlesColour }};
      --lead-capture-description-font: {{ branding.bodyFont }};
      --lead-capture-description-colour: {{ branding.bodyColour }};
      --lead-capture-button-font: {{ branding.bodyFont }};
      --lead-capture-button-background-colour: {{ branding.buttonBackground }};
      --lead-capture-button-text-colour: {{ branding.buttonText }};
      }
    </Component>
  </Head>

  <div class="grid grid-cols-1 lg:grid-cols-2 min-h-screen">
    <div
      :style="{ backgroundImage: `url(${props.content.defaultImageSelected ? `/${props.content.selectedImageUrl}` : props.content.selectedImageUrl})` }"
      class="bg-cover bg-no-repeat col-span-1 bg-gray-500 py-20 lg:py-0 block"
      :class="`bg-${props.content.imagePosition ?? 'center'}`"
    />
    <div class="col-span-1 flex flex-col justify-center py-8 px-6 py-lg:py-16 lg:px-16">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { Head, usePage } from '@inertiajs/vue3';
import collect from 'collect.js';
import initializeGtm from '../Support/initializeGtm';

const props = defineProps<{
  content: App.Data.LeadCaptureHomepageData,
  branding: App.Data.BrandData,
  branch: App.Data.BranchData,
  url: string
}>();

const page = usePage();

const fonts = computed(() => ({
  title: props.branding.titlesFont ? props.branding.titlesFont.replace(/[' ']/g, '+') : 'Inter',
  body: props.branding.bodyFont ? props.branding.bodyFont.replace(/[' ']/g, '+') : 'Inter',
  buttons: props.branding.buttonFont ? props.branding.buttonFont.replace(/[' ']/g, '+') : 'Inter',
}));

onMounted(() => {
  if (!props.branch.branding.googleTagManager) {
    return;
  }

  if (!collect(document.querySelectorAll('script')).pluck('src').contains(`https://www.googletagmanager.com/gtm.js?id=${props.branch.branding.googleTagManager}`)) {
    initializeGtm(props.branch.branding.googleTagManager);
  }
});
</script>
